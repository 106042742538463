import React from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';

const FineTuneCard = () => {
  const models = ["Model 1", "Model 2", "Model 3"]; // Add your model options here
  const [selectedModel, setSelectedModel] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [prompt, setPrompt] = React.useState("");
  const [qaList, setQaList] = React.useState([{ question: "", answer: "" }]);

  const handleAddQA = () => {
    setQaList([...qaList, { question: "", answer: "" }]);
  };

  const handleRemoveQA = (index) => {
    setQaList(qaList.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        backgroundColor: "#1a1a1a",
        color: "#fff",
        height: "70vh",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom style={{ textAlign: "left" }}>
          Finetune
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <TextField
          select
          label="Select Model"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          variant="outlined"
          sx={{
            flex: 1,
            backgroundColor: "#333",
            color: "#fff",
            "&::placeholder": {
              color: "#fff", // Placeholder color
              opacity: 1,
            },
          }}
        >
          {models.map((model, index) => (
            <MenuItem key={index} value={model}>
              {model}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Enter Suffix"
          value={suffix}
          onChange={(e) => setSuffix(e.target.value)}
          variant="outlined"
          sx={{ flex: 1, backgroundColor: "#333", color: "#fff" }}
        />
      </Box>

      <TextField
        label="System Prompt (Role)"
        placeholder="e.g. Hey! Enter the System Prompt"
        multiline
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        variant="outlined"
        fullWidth
        sx={{ backgroundColor: "#333", marginBottom: 2, color: "#fff" }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#555", marginBottom: 2 }}
          onClick={handleAddQA}
        >
          Generate
        </Button>
      </Box>

      {qaList.map((qa, index) => (
        <Card key={index} sx={{ backgroundColor: "#2c2c2c", marginBottom: 2 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ color: "#fff" }}>
                User (Question {index + 1})
              </Typography>
              <IconButton color="error" onClick={() => handleRemoveQA(index)}>
                {/* <DeleteIcon /> */}
              </IconButton>
            </Box>

            <TextField
              placeholder="e.g. Hey! How may we be of assistance today?"
              multiline
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "#333", marginBottom: 2, color: "#fff" }}
              value={qa.question}
              onChange={(e) => {
                const newQaList = [...qaList];
                newQaList[index].question = e.target.value;
                setQaList(newQaList);
              }}
            />

            <Typography variant="h6" sx={{ textAlign: "left", color: "#fff" }}>
              Assistant (Answer {index + 1})
            </Typography>
            <TextField
              placeholder="e.g. You are a customer support assistant..."
              multiline
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "#333", marginBottom: 2, color: "#fff" }}
              value={qa.answer}
              onChange={(e) => {
                const newQaList = [...qaList];
                newQaList[index].answer = e.target.value;
                setQaList(newQaList);
              }}
            />
          </CardContent>
        </Card>
      ))}

      <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Save
      </Button>
    </Box>
  );
};

export default FineTuneCard;
