import { useEffect, useState } from "react";
import axios from "axios";
import "./CoursesMainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faTimes,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

import SidebarDataset from "../SidebarDataset/sidebardataset";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

function CoursesMainContent({ isDarkMode }) {
  // Styles
  const popupStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    popup: {
      backgroundColor: "black",
      padding: "30px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "400px",
      textAlign: "left",
      border: "1px solid #fff",
    },
  };

  const formStyles = {
    display: "flex",
    flexDirection: "column",
  };

  const formFieldStyles = {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  };

  const inputStyles = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "97%",
    marginRight: "20px",
  };

  const submitButtonStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    marginTop: "25px",
  };

  const closeButtonStyles = {
    marginLeft: "10px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    marginTop: "25px",
  };

  const indexes = [
    {
      name: "Index 1",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 2",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 3",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 4",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 5",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
  ];

  const subjects = {
    "Exam 1": [
      "Subject 1",
      "Subject 2",
      "Subject 3",
      "Subject 4",
      "Subject 5",
      "Subject 6",
      "Subject 7",
      "Subject 8",
      "Subject 9",
      "Subject 10",
    ],
    "Exam 2": [
      "Subject 11",
      "Subject 12",
      "Subject 13",
      "Subject 14",
      "Subject 15",
      "Subject 16",
      "Subject 17",
      "Subject 18",
      "Subject 19",
      "Subject 20",
    ],
    "Exam 3": [
      "Subject 21",
      "Subject 22",
      "Subject 23",
      "Subject 24",
      "Subject 25",
      "Subject 26",
      "Subject 27",
      "Subject 28",
      "Subject 29",
      "Subject 30",
    ],
    "Exam 4": [
      "Subject 31",
      "Subject 32",
      "Subject 33",
      "Subject 34",
      "Subject 35",
      "Subject 36",
      "Subject 37",
      "Subject 38",
      "Subject 39",
      "Subject 40",
    ],
    "Exam 5": [
      "Subject 41",
      "Subject 42",
      "Subject 43",
      "Subject 44",
      "Subject 45",
      "Subject 46",
      "Subject 47",
      "Subject 48",
      "Subject 49",
      "Subject 50",
    ],
    "Exam 6": [
      "Subject 51",
      "Subject 52",
      "Subject 53",
      "Subject 54",
      "Subject 55",
      "Subject 56",
      "Subject 57",
      "Subject 58",
      "Subject 59",
      "Subject 60",
    ],
    "Exam 7": [
      "Subject 61",
      "Subject 62",
      "Subject 63",
      "Subject 64",
      "Subject 65",
      "Subject 66",
      "Subject 67",
      "Subject 68",
      "Subject 69",
      "Subject 70",
    ],
    "Exam 8": [
      "Subject 71",
      "Subject 72",
      "Subject 73",
      "Subject 74",
      "Subject 75",
      "Subject 76",
      "Subject 77",
      "Subject 78",
      "Subject 79",
      "Subject 80",
    ],
    "Exam 9": [
      "Subject 81",
      "Subject 82",
      "Subject 83",
      "Subject 84",
      "Subject 85",
      "Subject 86",
      "Subject 87",
      "Subject 88",
      "Subject 89",
      "Subject 90",
    ],
    "Exam 10": [
      "Subject 91",
      "Subject 92",
      "Subject 93",
      "Subject 94",
      "Subject 95",
      "Subject 96",
      "Subject 97",
      "Subject 98",
      "Subject 99",
      "Subject 100",
    ],
  };

  const topics = {
    "Subject 1": [
      "Topic 1.1",
      "Topic 1.2",
      "Topic 1.3",
      "Topic 1.4",
      "Topic 1.5",
      "Topic 1.6",
      "Topic 1.7",
      "Topic 1.8",
      "Topic 1.9",
      "Topic 1.10",
    ],
    "Subject 2": [
      "Topic 2.1",
      "Topic 2.2",
      "Topic 2.3",
      "Topic 2.4",
      "Topic 2.5",
      "Topic 2.6",
      "Topic 2.7",
      "Topic 2.8",
      "Topic 2.9",
      "Topic 2.10",
    ],
    "Subject 3": [
      "Topic 3.1",
      "Topic 3.2",
      "Topic 3.3",
      "Topic 3.4",
      "Topic 3.5",
      "Topic 3.6",
      "Topic 3.7",
      "Topic 3.8",
      "Topic 3.9",
      "Topic 3.10",
    ],
    "Subject 4": [
      "Topic 4.1",
      "Topic 4.2",
      "Topic 4.3",
      "Topic 4.4",
      "Topic 4.5",
      "Topic 4.6",
      "Topic 4.7",
      "Topic 4.8",
      "Topic 4.9",
      "Topic 4.10",
    ],
    "Subject 5": [
      "Topic 5.1",
      "Topic 5.2",
      "Topic 5.3",
      "Topic 5.4",
      "Topic 5.5",
      "Topic 5.6",
      "Topic 5.7",
      "Topic 5.8",
      "Topic 5.9",
      "Topic 5.10",
    ],
    "Subject 6": [
      "Topic 6.1",
      "Topic 6.2",
      "Topic 6.3",
      "Topic 6.4",
      "Topic 6.5",
      "Topic 6.6",
      "Topic 6.7",
      "Topic 6.8",
      "Topic 6.9",
      "Topic 6.10",
    ],
    "Subject 7": [
      "Topic 7.1",
      "Topic 7.2",
      "Topic 7.3",
      "Topic 7.4",
      "Topic 7.5",
      "Topic 7.6",
      "Topic 7.7",
      "Topic 7.8",
      "Topic 7.9",
      "Topic 7.10",
    ],
    "Subject 8": [
      "Topic 8.1",
      "Topic 8.2",
      "Topic 8.3",
      "Topic 8.4",
      "Topic 8.5",
      "Topic 8.6",
      "Topic 8.7",
      "Topic 8.8",
      "Topic 8.9",
      "Topic 8.10",
    ],
    "Subject 9": [
      "Topic 9.1",
      "Topic 9.2",
      "Topic 9.3",
      "Topic 9.4",
      "Topic 9.5",
      "Topic 9.6",
      "Topic 9.7",
      "Topic 9.8",
      "Topic 9.9",
      "Topic 9.10",
    ],
    "Subject 10": [
      "Topic 10.1",
      "Topic 10.2",
      "Topic 10.3",
      "Topic 10.4",
      "Topic 10.5",
      "Topic 10.6",
      "Topic 10.7",
      "Topic 10.8",
      "Topic 10.9",
      "Topic 10.10",
    ],
  };

  const subTopics = {
    "Topic 1.1": [
      "SubTopic 1.1.1",
      "SubTopic 1.1.2",
      "SubTopic 1.1.3",
      "SubTopic 1.1.4",
      "SubTopic 1.1.5",
      "SubTopic 1.1.6",
      "SubTopic 1.1.7",
      "SubTopic 1.1.8",
      "SubTopic 1.1.9",
      "SubTopic 1.1.10",
    ],
    "Topic 2.1": [
      "SubTopic 2.1.1",
      "SubTopic 2.1.2",
      "SubTopic 2.1.3",
      "SubTopic 2.1.4",
      "SubTopic 2.1.5",
      "SubTopic 2.1.6",
      "SubTopic 2.1.7",
      "SubTopic 2.1.8",
      "SubTopic 2.1.9",
      "SubTopic 2.1.10",
    ],
    "Topic 3.1": [
      "SubTopic 3.1.1",
      "SubTopic 3.1.2",
      "SubTopic 3.1.3",
      "SubTopic 3.1.4",
      "SubTopic 3.1.5",
      "SubTopic 3.1.6",
      "SubTopic 3.1.7",
      "SubTopic 3.1.8",
      "SubTopic 3.1.9",
      "SubTopic 3.1.10",
    ],
    "Topic 4.1": [
      "SubTopic 4.1.1",
      "SubTopic 4.1.2",
      "SubTopic 4.1.3",
      "SubTopic 4.1.4",
      "SubTopic 4.1.5",
      "SubTopic 4.1.6",
      "SubTopic 4.1.7",
      "SubTopic 4.1.8",
      "SubTopic 4.1.9",
      "SubTopic 4.1.10",
    ],
    "Topic 5.1": [
      "SubTopic 5.1.1",
      "SubTopic 5.1.2",
      "SubTopic 5.1.3",
      "SubTopic 5.1.4",
      "SubTopic 5.1.5",
      "SubTopic 5.1.6",
      "SubTopic 5.1.7",
      "SubTopic 5.1.8",
      "SubTopic 5.1.9",
      "SubTopic 5.1.10",
    ],
    "Topic 6.1": [
      "SubTopic 6.1.1",
      "SubTopic 6.1.2",
      "SubTopic 6.1.3",
      "SubTopic 6.1.4",
      "SubTopic 6.1.5",
      "SubTopic 6.1.6",
      "SubTopic 6.1.7",
      "SubTopic 6.1.8",
      "SubTopic 6.1.9",
      "SubTopic 6.1.10",
    ],
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [aivoicetab, setAiVoiceTab] = useState(true);
  const [aiChatTab, setAiChatTab] = useState(false);
  const [aiTestTab, setAiTestTab] = useState(false);

  const [openLinkIndexPopup, setIsLinkIndexPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const togglePopup = () => {
    setIsFormOpen(!isFormOpen);
  };

  const [formData, setFormData] = useState({
    courseName: "",
    exam: "",
    paper: "",
    ccr: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    console.log("Form Data:", formData);
  };

  const handleAiVoiceTab = () => {
    setAiVoiceTab(true);
    setAiChatTab(false);
    setAiTestTab(false);
  };

  const handleAiChatTab = () => {
    setAiVoiceTab(false);
    setAiChatTab(true);
    setAiTestTab(false);
  };

  const handleAiTestTab = () => {
    setAiVoiceTab(false);
    setAiChatTab(false);
    setAiTestTab(true);
  };

  const closeLinkIndexPopup = () => {
    setIsLinkIndexPopup(false);
    setSelectedIndex("");
    setSelectedExam("");
    setSelectedSubject("");
    setSelectedTopic("");
    setSelectedSubTopic("");
  };

  return (
    <div className="mainDatasetContainer">
      <Drawer
        PaperProps={{
          style: {
            backgroundColor: "#252525",
            color: "#ffffff",
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div className="drawerHeader">
          <IconButton
            onClick={toggleDrawer(false)}
            className="drawerCloseButton"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        <List className="deager">
          <SidebarDataset />
        </List>
      </Drawer>

      <div className="sidebarmobile">
        <SidebarDataset />
      </div>

      <div className="courses-main-content">
        <div className="courses-header-cont">
          <h1>Courses</h1>
          <label
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginBottom: "30px",
            }}
            onClick={togglePopup}
          >
            Add Course
          </label>

          {isFormOpen && (
            <div style={popupStyles.overlay}>
              <div style={popupStyles.popup}>
                <h2 style={{ margin: 0, textAlign: "center" }}>
                  Upload Course
                </h2>
                <form onSubmit={handleSubmit} style={formStyles}>
                  <div style={formFieldStyles}>
                    <label> Course Name</label>
                    <input
                      type="text"
                      name="courseName"
                      value={formData.courseName}
                      onChange={handleInputChange}
                      style={inputStyles}
                      required
                    />
                  </div>

                  <div style={formFieldStyles}>
                    <label> Course Details</label>
                    <input
                      type="text"
                      name="courseDetails"
                      value={formData.courseDetails}
                      onChange={handleInputChange}
                      style={inputStyles}
                      required
                    />
                  </div>

                  <div style={formFieldStyles}>
                    <label>CCR (Credit Consumption Rate) </label>
                    <input
                      name="ccr"
                      type="number"
                      value={formData.ccr}
                      onChange={handleInputChange}
                      style={inputStyles}
                      required
                    />
                  </div>

                  <div style={formFieldStyles}>
                      <button
                        style={submitButtonStyles}
                        type="button"
                        onClick={()=>{setIsLinkIndexPopup(true)}}
                      >
                        Link Index
                      </button>
                      <p style={{ fontSize: "10px", marginTop: "0px" }}>
                        Linked Index Value
                      </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      type="button"
                      onClick={togglePopup}
                      style={closeButtonStyles}
                    >
                      Cancel
                    </button>
                    <button type="submit" style={submitButtonStyles}>
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        <div className="tabs-container">
          <Button
            variant="outlined"
            sx={{ color: "#fff", border: "1px solid #fff" }}
            onClick={handleAiVoiceTab}
          >
            {" "}
            Ai Voice
          </Button>
          <Button
            variant="outlined"
            sx={{ color: "#fff", border: "1px solid #fff" }}
            onClick={handleAiChatTab}
          >
            {" "}
            Ai Chat
          </Button>
          <Button
            variant="outlined"
            sx={{ color: "#fff", border: "1px solid #fff" }}
            onClick={handleAiTestTab}
          >
            {" "}
            Ai Test
          </Button>
        </div>

        <div className="course-cards-cont">
          <div class="course-card">
            <h2>React Js</h2>
            <p>
              <strong>Exam/Course:</strong> Advanced React
            </p>
            <p>
              <strong>Paper/Section:</strong> Section3
            </p>
            <p>
              <strong>CCR:</strong> 1.5
            </p>

            <button class="link-index-btn" onClick={()=>{setIsLinkIndexPopup(true)}}>Add Index</button>
          </div>
          <div class="course-card">
            <h2>React Js</h2>
            <p>
              <strong>Exam/Course:</strong> Advanced React
            </p>
            <p>
              <strong>Paper/Section:</strong> Section3
            </p>
            <p>
              <strong>CCR:</strong> 1.5
            </p>

            <button class="link-index-btn">Link Index</button>
          </div>
          <div class="course-card">
            <h2>React Js</h2>
            <p>
              <strong>Exam/Course:</strong> Advanced React
            </p>
            <p>
              <strong>Paper/Section:</strong> Section3
            </p>
            <p>
              <strong>CCR:</strong> 1.5
            </p>

            <button class="link-index-btn">Link Index</button>
          </div>
          <div class="course-card">
            <h2>React Js</h2>
            <p>
              <strong>Exam/Course:</strong> Advanced React
            </p>
            <p>
              <strong>Paper/Section:</strong> Section3
            </p>
            <p>
              <strong>CCR:</strong> 1.5
            </p>

            <button class="link-index-btn">Link Index</button>
          </div>
        </div>

        {/* {
            aivoicetab && <h1>AI VOICE TAB</h1>
        }
        {
            aiChatTab && <h1>AI CHAT TAB </h1>
        }
        {
             aiTestTab && <h1>AI TEST TAB </h1>
        } */}

        {openLinkIndexPopup && (
          <div>
            <div>
              {/* <h2 style={{ margin: 0, textAlign: "center" }}>
                              Link Index
                            </h2> */}
              <form onSubmit={handleSubmit} style={formStyles}>
                <Drawer
                  anchor="right"
                  open={openLinkIndexPopup}
                  PaperProps={{
                    sx: {
                      width: "350px",
                      padding: "20px",
                      background: "#000",
                      borderLeft: "1px solid #fff",
                    },
                  }}
                >
                  {/* Select Index */}
                  <FormControl fullWidth margin="normal">
                    <InputLabel sx={{ color: "#fff" }}>Select Index</InputLabel>
                    <Select
                      value={selectedIndex}
                      onChange={(e) => {
                        setSelectedIndex(e.target.value);
                        setSelectedExam("");
                        setSelectedSubject("");
                        setSelectedTopic("");
                      }}
                      sx={{
                        border: "1px solid #fff",
                        color:"#fff"
                      }}
                    >
                      {indexes.map((index, idx) => (
                        <MenuItem key={idx} value={index} sx={{backgroundColor:"#000",color:"#fff"}}>
                          {index.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Select Exam */}
                  {selectedIndex && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel sx={{ color: "#fff" }}>
                        Select Exam
                      </InputLabel>
                      <Select
                        value={selectedExam}
                        onChange={(e) => {
                          setSelectedExam(e.target.value);
                          setSelectedSubject("");
                          setSelectedTopic("");
                        }}
                        sx={{ border: "1px solid #fff",color:"#fff" }}
                      >
                        {selectedIndex.exams.map((exam, idx) => (
                          <MenuItem key={idx} value={exam} sx={{backgroundColor:"#000",color:"#fff"}}>
                            {exam}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {/* Select Subject */}
                  {selectedExam && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel sx={{ color: "#fff" }}>
                        Select Subject
                      </InputLabel>
                      <Select
                        value={selectedSubject}
                        onChange={(e) => {
                          setSelectedSubject(e.target.value);
                          setSelectedTopic("");
                        }}
                        sx={{ border: "1px solid #fff" }}
                      >
                        {subjects[selectedExam].map((subject, idx) => (
                          <MenuItem key={idx} value={subject}>
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {/* Select Topic */}
                  {selectedSubject && (
                    <FormControl fullWidth margin="normal">
                      <InputLabel sx={{ color: "#fff" }}>
                        Select Topic
                      </InputLabel>
                      <Select
                        value={selectedTopic}
                        onChange={(e) => setSelectedTopic(e.target.value)}
                        sx={{ border: "1px solid #fff" }}
                      >
                        {topics[selectedSubject].map((topic, idx) => (
                          <MenuItem key={idx} value={topic}>
                            {topic}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {/* Display SubTopics */}
                  {selectedTopic && (
                    <FormControl>
                      <InputLabel sx={{ color: "#fff" }}>
                        Select SubTopic
                      </InputLabel>
                      <Select
                        fullWidth
                        value={selectedSubTopic}
                        onChange={(e) => setSelectedSubTopic(e.target.value)}
                        sx={{ border: "1px solid #fff" }}
                      >
                        {subTopics[selectedTopic].map((subTopic, idx) => (
                          <MenuItem key={idx} value={subTopic}>
                            {subTopic}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "100px",
                    }}
                  >
                    <button
                      variant="contained"
                      onClick={closeLinkIndexPopup}
                      style={closeButtonStyles}
                    >
                      Cancel
                    </button>

                    <button
                      variant="contained"
                      style={submitButtonStyles}
                      // onClick={handleDrawerClose}
                    >
                      Link Index
                    </button>
                  </div>
                </Drawer>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CoursesMainContent;
