import { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";

import { ClipLoader } from "react-spinners";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import "./StorageMainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faTimes,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

import SidebarDataset from "../SidebarDataset/sidebardataset";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
/*
// Set the workerSrc for PDF.js
import { pdfjs as PdfJsLib } from 'react-pdf';
import 'pdfjs-dist/webpack';

PdfJsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PdfJsLib.version}/pdf.worker.min.js`;


*/

//icon sidebar

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

function StorageMainContent({ isDarkMode }) {
  const indexes = [
    {
      name: "Index 1",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 2",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 3",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 4",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
    {
      name: "Index 5",
      exams: [
        "Exam 1",
        "Exam 2",
        "Exam 3",
        "Exam 4",
        "Exam 5",
        "Exam 6",
        "Exam 7",
        "Exam 8",
        "Exam 9",
        "Exam 10",
      ],
    },
  ];

  const subjects = {
    "Exam 1": [
      "Subject 1",
      "Subject 2",
      "Subject 3",
      "Subject 4",
      "Subject 5",
      "Subject 6",
      "Subject 7",
      "Subject 8",
      "Subject 9",
      "Subject 10",
    ],
    "Exam 2": [
      "Subject 11",
      "Subject 12",
      "Subject 13",
      "Subject 14",
      "Subject 15",
      "Subject 16",
      "Subject 17",
      "Subject 18",
      "Subject 19",
      "Subject 20",
    ],
    "Exam 3": [
      "Subject 21",
      "Subject 22",
      "Subject 23",
      "Subject 24",
      "Subject 25",
      "Subject 26",
      "Subject 27",
      "Subject 28",
      "Subject 29",
      "Subject 30",
    ],
    "Exam 4": [
      "Subject 31",
      "Subject 32",
      "Subject 33",
      "Subject 34",
      "Subject 35",
      "Subject 36",
      "Subject 37",
      "Subject 38",
      "Subject 39",
      "Subject 40",
    ],
    "Exam 5": [
      "Subject 41",
      "Subject 42",
      "Subject 43",
      "Subject 44",
      "Subject 45",
      "Subject 46",
      "Subject 47",
      "Subject 48",
      "Subject 49",
      "Subject 50",
    ],
    "Exam 6": [
      "Subject 51",
      "Subject 52",
      "Subject 53",
      "Subject 54",
      "Subject 55",
      "Subject 56",
      "Subject 57",
      "Subject 58",
      "Subject 59",
      "Subject 60",
    ],
    "Exam 7": [
      "Subject 61",
      "Subject 62",
      "Subject 63",
      "Subject 64",
      "Subject 65",
      "Subject 66",
      "Subject 67",
      "Subject 68",
      "Subject 69",
      "Subject 70",
    ],
    "Exam 8": [
      "Subject 71",
      "Subject 72",
      "Subject 73",
      "Subject 74",
      "Subject 75",
      "Subject 76",
      "Subject 77",
      "Subject 78",
      "Subject 79",
      "Subject 80",
    ],
    "Exam 9": [
      "Subject 81",
      "Subject 82",
      "Subject 83",
      "Subject 84",
      "Subject 85",
      "Subject 86",
      "Subject 87",
      "Subject 88",
      "Subject 89",
      "Subject 90",
    ],
    "Exam 10": [
      "Subject 91",
      "Subject 92",
      "Subject 93",
      "Subject 94",
      "Subject 95",
      "Subject 96",
      "Subject 97",
      "Subject 98",
      "Subject 99",
      "Subject 100",
    ],
  };

  const topics = {
    "Subject 1": [
      "Topic 1.1",
      "Topic 1.2",
      "Topic 1.3",
      "Topic 1.4",
      "Topic 1.5",
      "Topic 1.6",
      "Topic 1.7",
      "Topic 1.8",
      "Topic 1.9",
      "Topic 1.10",
    ],
    "Subject 2": [
      "Topic 2.1",
      "Topic 2.2",
      "Topic 2.3",
      "Topic 2.4",
      "Topic 2.5",
      "Topic 2.6",
      "Topic 2.7",
      "Topic 2.8",
      "Topic 2.9",
      "Topic 2.10",
    ],
    "Subject 3": [
      "Topic 3.1",
      "Topic 3.2",
      "Topic 3.3",
      "Topic 3.4",
      "Topic 3.5",
      "Topic 3.6",
      "Topic 3.7",
      "Topic 3.8",
      "Topic 3.9",
      "Topic 3.10",
    ],
    "Subject 4": [
      "Topic 4.1",
      "Topic 4.2",
      "Topic 4.3",
      "Topic 4.4",
      "Topic 4.5",
      "Topic 4.6",
      "Topic 4.7",
      "Topic 4.8",
      "Topic 4.9",
      "Topic 4.10",
    ],
    "Subject 5": [
      "Topic 5.1",
      "Topic 5.2",
      "Topic 5.3",
      "Topic 5.4",
      "Topic 5.5",
      "Topic 5.6",
      "Topic 5.7",
      "Topic 5.8",
      "Topic 5.9",
      "Topic 5.10",
    ],
    "Subject 6": [
      "Topic 6.1",
      "Topic 6.2",
      "Topic 6.3",
      "Topic 6.4",
      "Topic 6.5",
      "Topic 6.6",
      "Topic 6.7",
      "Topic 6.8",
      "Topic 6.9",
      "Topic 6.10",
    ],
    "Subject 7": [
      "Topic 7.1",
      "Topic 7.2",
      "Topic 7.3",
      "Topic 7.4",
      "Topic 7.5",
      "Topic 7.6",
      "Topic 7.7",
      "Topic 7.8",
      "Topic 7.9",
      "Topic 7.10",
    ],
    "Subject 8": [
      "Topic 8.1",
      "Topic 8.2",
      "Topic 8.3",
      "Topic 8.4",
      "Topic 8.5",
      "Topic 8.6",
      "Topic 8.7",
      "Topic 8.8",
      "Topic 8.9",
      "Topic 8.10",
    ],
    "Subject 9": [
      "Topic 9.1",
      "Topic 9.2",
      "Topic 9.3",
      "Topic 9.4",
      "Topic 9.5",
      "Topic 9.6",
      "Topic 9.7",
      "Topic 9.8",
      "Topic 9.9",
      "Topic 9.10",
    ],
    "Subject 10": [
      "Topic 10.1",
      "Topic 10.2",
      "Topic 10.3",
      "Topic 10.4",
      "Topic 10.5",
      "Topic 10.6",
      "Topic 10.7",
      "Topic 10.8",
      "Topic 10.9",
      "Topic 10.10",
    ],
  };

  const subTopics = {
    "Topic 1.1": [
      "SubTopic 1.1.1",
      "SubTopic 1.1.2",
      "SubTopic 1.1.3",
      "SubTopic 1.1.4",
      "SubTopic 1.1.5",
      "SubTopic 1.1.6",
      "SubTopic 1.1.7",
      "SubTopic 1.1.8",
      "SubTopic 1.1.9",
      "SubTopic 1.1.10",
    ],
    "Topic 2.1": [
      "SubTopic 2.1.1",
      "SubTopic 2.1.2",
      "SubTopic 2.1.3",
      "SubTopic 2.1.4",
      "SubTopic 2.1.5",
      "SubTopic 2.1.6",
      "SubTopic 2.1.7",
      "SubTopic 2.1.8",
      "SubTopic 2.1.9",
      "SubTopic 2.1.10",
    ],
    "Topic 3.1": [
      "SubTopic 3.1.1",
      "SubTopic 3.1.2",
      "SubTopic 3.1.3",
      "SubTopic 3.1.4",
      "SubTopic 3.1.5",
      "SubTopic 3.1.6",
      "SubTopic 3.1.7",
      "SubTopic 3.1.8",
      "SubTopic 3.1.9",
      "SubTopic 3.1.10",
    ],
    "Topic 4.1": [
      "SubTopic 4.1.1",
      "SubTopic 4.1.2",
      "SubTopic 4.1.3",
      "SubTopic 4.1.4",
      "SubTopic 4.1.5",
      "SubTopic 4.1.6",
      "SubTopic 4.1.7",
      "SubTopic 4.1.8",
      "SubTopic 4.1.9",
      "SubTopic 4.1.10",
    ],
    "Topic 5.1": [
      "SubTopic 5.1.1",
      "SubTopic 5.1.2",
      "SubTopic 5.1.3",
      "SubTopic 5.1.4",
      "SubTopic 5.1.5",
      "SubTopic 5.1.6",
      "SubTopic 5.1.7",
      "SubTopic 5.1.8",
      "SubTopic 5.1.9",
      "SubTopic 5.1.10",
    ],
    "Topic 6.1": [
      "SubTopic 6.1.1",
      "SubTopic 6.1.2",
      "SubTopic 6.1.3",
      "SubTopic 6.1.4",
      "SubTopic 6.1.5",
      "SubTopic 6.1.6",
      "SubTopic 6.1.7",
      "SubTopic 6.1.8",
      "SubTopic 6.1.9",
      "SubTopic 6.1.10",
    ],
  };

  const popupStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    popup: {
      backgroundColor: "black",
      padding: "30px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "400px",
      textAlign: "left",
      border: "1px solid #fff",
    },
  };

  const formStyles = {
    display: "flex",
    flexDirection: "column",
  };

  const formFieldStyles = {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  };

  const inputStyles = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "97%",
    marginRight: "20px",
  };

  const submitButtonStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    marginTop: "25px",
  };

  const closeButtonStyles = {
    marginLeft: "10px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    marginTop: "25px",
  };

  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [allFiles, setAllFiles] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [uploadmessage, setUploadMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [category, setCategory] = useState("");
  //loading states
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState({});
  /*const [embedingLoading,setEmbedingLoading] = useState(false)*/
  const [embeddingLoading, setEmbeddingLoading] = useState({});
  const [confirmPop, setConfirmPopup] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = async () => {
    try {
      setLoadingFiles(true);

      const response = await axios.get(
        `https://legai.onrender.com/get-files?term=${searchTerm}`
      );

      const result = response.data.data;
      console.log(result);

      // If selectType is empty, show all files; otherwise, filter by selectType
      const filteredFiles = selectType
        ? result.filter((item) => item.filetype === `application/${selectType}`)
        : result;

      // Set the state to the filtered or all files
      setAllFiles(filteredFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoadingFiles(false);
      alert("failed to fetch");
    } finally {
      setLoadingFiles(false);
    }
  };

  const submitFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);

    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()}`;
    const formattedTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

    formData.append("uploadDate", formattedDate);
    formData.append("time", formattedTime);
    formData.append("category", category);
    setUploadingFile(true);

    try {
      const result = await axios.post(
        "https://legai.onrender.com/upload-files",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (result.data.status === "ok") {
        alert("Uploaded Successfully!!!");
        // close();
        setTitle("");
        setFile("");
        getFiles();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadingFile(false);
      alert("failed to upload");
    } finally {
      setUploadingFile(false);
    }

    document.getElementById("titleinput").value = "";
    document.getElementById("fileinputEle").value = "";
  };

  const showFile = (file) => {
    setFileUrl(`https://legai.onrender.com/files/${file}`);
  };

  const onchangeSearch = (e) => {
    setSearchTerm(e.target.value);

    if (document.getElementById("searchElement").value === "") {
      getFiles();
      setSearchTerm("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getFiles();
    }
  };

  const searchFile = async () => {
    try {
      setLoadingFiles(true);
      const result = await axios.get(
        `https://legai.onrender.com/api/search-files?term=${searchTerm}`
      );
      if (result.data.status === "ok") {
        setAllFiles(result.data.data);
      }
    } catch (error) {
      console.error("Error searching files:", error);
    } finally {
      setLoadingFiles(false);
    }
  };

  const deleteFile = async (id) => {
    try {
      const updatedEmbeddingLoading = { ...deletingFile, [id]: true };
      setDeletingFile(updatedEmbeddingLoading);
      const result = await axios.delete(
        `https://legai.onrender.com/delete-file/${id}`
      );
      console.log(result);
      console.log(id);
      if (result.data.status === "ok") {
        alert("Deleted Successfully!!!");
        getFiles();
      }
    } catch (error) {
      console.error("Failed to delete file:", error);
    } finally {
      setEmbeddingLoading({ ...deletingFile, [id]: false });
    }
  };

  const openFileViewer = (file) => {
    setSelectedFile(file);
    setNumPages(null);
  };

  // Function to close file viewer popup
  const closeFileViewer = () => {
    setSelectedFile(null);
    setNumPages(null);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const EmbedCalled = async (data) => {
    console.log("embed clicked");
    const updatedEmbeddingLoading = { ...embeddingLoading, [data._id]: true };
    setEmbeddingLoading(updatedEmbeddingLoading);
    try {
      const formData = new FormData();
      formData.append("file", data.pdf);

      console.log("FormData content:", formData.get("file")); // Log the FormData content

      const response = await axios.post(
        "https://legai.onrender.com/embed-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert(response.data);

      console.log(response.data);
      getFiles();
    } catch (error) {
      console.error("Error embedding file:", error);
    } finally {
      setEmbeddingLoading({ ...embeddingLoading, [data._id]: false });
    }
  };

  const EmbededFunctioncall = (data) => {
    if (data.status === "True") {
      alert("Embeded already");
    } else {
      getFiles();
      setUploadMessage("embeded");
      alert("Embedding is Successful!!");
    }
  };

  const renderFilePreview = (file) => {
    if (file.filetype.startsWith("image/")) {
      return (
        <img
          src={`https://legai.onrender.com/files/${file._id}`}
          alt="File Preview"
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype.startsWith("video/")) {
      return (
        <video
          controls
          src={`https://legai.onrender.com/files/${file._id}`}
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype.startsWith("audio/")) {
      return (
        <audio
          controls
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype === "application/pdf") {
      return (
        <iframe
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%", height: "50vh" }}
        />
      );
    } else if (file.filetype.startsWith("text/")) {
      return (
        <iframe
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%", height: "600px" }}
        />
      );
    } else {
      return <p>File type not supported for preview.</p>;
    }
  };
 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectType, setSelectedType] = useState("");
  const [openLinkIndexPopup, setIsLinkIndexPopup] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSelectType = (e) => {
    setSelectedType(e.target.value);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const confirmDelete = (fileId) => {
    setConfirmPopup(true);
    setDeleteFileId(fileId);
  };

  const handleConfirmDelete = () => {
    deleteFile(deleteFileId);
    setConfirmPopup(false);
  };

  const handleDeleteCancel = () => {
    setConfirmPopup(false);
    setDeleteFileId(null);
  };

  const [formData, setFormData] = useState({
    courseName: "",
    exam: "",
    paper: "",
    ccr: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    console.log("Form Data:", formData);
  };

  const closeLinkIndexPopup = () => {
    setIsLinkIndexPopup(false);
    setSelectedIndex("");
    setSelectedExam("");
    setSelectedSubject("");
    setSelectedTopic("");
    setSelectedSubTopic("");
  };

  // Reset function to set defaults
const resetToDefault = () => {
  setSelectedOption("");
};

  return (
    <div className="mainDatasetContainer">
      <Drawer
        PaperProps={{
          style: {
            backgroundColor: "#252525",
            color: "#ffffff",
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div className="drawerHeader">
          <IconButton
            onClick={toggleDrawer(false)}
            className="drawerCloseButton"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        <List className="deager">
          <SidebarDataset />
        </List>
      </Drawer>

      <div className="sidebarmobile">
        <SidebarDataset />
      </div>

      <div
        className={`  storage-main-content storageDatasetmobile ${
          isDarkMode ? "dark-mode" : "light-mode"
        }`}
      >
        <div className="header">
          <div className="arrowcont">
            {/* <IconButton
                className="sidebaropenbutton"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <FontAwesomeIcon className="drawericon" icon={faGreaterThan} />
              </IconButton> */}
            <h3
              className="storagenamehead"
              style={{ marginBottom: "25px", borderBottom: "2px solid #fff" }}
            >
              Knowledge
            </h3>
          </div>

          {/* <hr style={{color:"#fff",width:"100%",marginBottom:"20px"}}/> */}
          <div className="uploadButtonCont">
            {/* <div className="arrowcont">
              <IconButton
                className="sidebaropenbutton"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <FontAwesomeIcon className="drawericon" icon={faGreaterThan} />
              </IconButton>
              <h3 className="storagenamehead">Knowledge Base</h3>
            </div> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                gap: "30px",
              }}
            >
              <input
                placeholder="Search"
                type="search"
                id="searchElement"
                onKeyPress={(e) => handleKeyPress(e)}
                value={searchTerm}
                onChange={onchangeSearch}
                style={{
                  width: "180px",
                  padding: "10px",
                  fontSize: "14px",
                  border: "1px solid #333", // Dark border
                  borderRadius: "5px",
                  backgroundColor: "#000", // Black background
                  color: "#fff", // White text
                  marginRight: "10px", // Space between input and dropdown
                }}
              />

              <select
                id="fileTypeSelect"
                onChange={handleSelectType}
                style={{
                  width: "200px",
                  padding: "10px",
                  fontSize: "14px",
                  border: "1px solid #333", // Dark border
                  borderRadius: "5px",
                  backgroundColor: "#000", // Black background
                  color: "#fff", // White text
                  cursor: "pointer",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  outline: "none",
                  appearance: "none", // Hides the default arrow in some browsers
                }}
              >
                <option
                  value=""
                  style={{ color: "#999", backgroundColor: "#000" }}
                >
                  Select a file type
                </option>
                <option
                  value="pdf"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  File
                </option>
                <option
                  value="doc"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Web Page
                </option>
                <option
                  value="xls"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Youtube Link
                </option>
                <option
                  value="ppt"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Text
                </option>
                
              </select>
            </div>

            <select
              id="recordTypeSelect"
              onChange={handleSelectChange}
              value={selectedOption}
              style={{
                width: "200px",
                padding: "10px",
                fontSize: "14px",
                border: "1px solid #333", // Darker border to match the theme
                borderRadius: "5px",
                backgroundColor: "#000", // Black background
                color: "#fff", // White text
                cursor: "pointer",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                outline: "none",
                appearance: "none", // Hides the default arrow in some browsers
                marginBottom: "15px",
              }}
            >
              <option
                value=""
                style={{ color: "#999", backgroundColor: "#000" }}
              >
                Add Knowledge
              </option>
              
              <option
                value="file"
                style={{ color: "#fff", backgroundColor: "#000" }}
              >
                File
              </option>
              <option
                value="webPage"
                style={{ color: "#fff", backgroundColor: "#000" }}
              >
                Web Page
              </option>
              <option
                value="youtubeLink"
                style={{ color: "#fff", backgroundColor: "#000" }}
              >
                YouTube Link
              </option>
              <option
                value="text"
                style={{ color: "#fff", backgroundColor: "#000" }}
              >
                Text
              </option>
            </select>

            {openLinkIndexPopup && (
              <div>
                <div>
                  {/* <h2 style={{ margin: 0, textAlign: "center" }}>
                              Link Index
                            </h2> */}
                  <form onSubmit={handleSubmit} style={formStyles}>
                    <Drawer
                      anchor="right"
                      open={openLinkIndexPopup}
                      PaperProps={{
                        sx: {
                          width: "350px",
                          padding: "20px",
                          background: "#000",
                          borderLeft: "1px solid #fff",
                        },
                      }}
                    >
                      {/* Select Index */}
                      <FormControl fullWidth margin="normal">
                        <InputLabel sx={{ color: "#fff" }}>
                          Select Index
                        </InputLabel>
                        <Select
                          value={selectedIndex}
                          onChange={(e) => {
                            setSelectedIndex(e.target.value);
                            setSelectedExam("");
                            setSelectedSubject("");
                            setSelectedTopic("");
                          }}
                          sx={{ border: "1px solid #fff" }}
                        >
                          {indexes.map((index, idx) => (
                            <MenuItem key={idx} value={index}>
                              {index.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Select Exam */}
                      {selectedIndex && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel sx={{ color: "#fff" }}>
                            Select Exam
                          </InputLabel>
                          <Select
                            value={selectedExam}
                            onChange={(e) => {
                              setSelectedExam(e.target.value);
                              setSelectedSubject("");
                              setSelectedTopic("");
                            }}
                            sx={{ border: "1px solid #fff" }}
                          >
                            {selectedIndex.exams.map((exam, idx) => (
                              <MenuItem key={idx} value={exam}>
                                {exam}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {/* Select Subject */}
                      {selectedExam && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel sx={{ color: "#fff" }}>
                            Select Subject
                          </InputLabel>
                          <Select
                            value={selectedSubject}
                            onChange={(e) => {
                              setSelectedSubject(e.target.value);
                              setSelectedTopic("");
                            }}
                            sx={{ border: "1px solid #fff" }}
                          >
                            {subjects[selectedExam].map((subject, idx) => (
                              <MenuItem key={idx} value={subject}>
                                {subject}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {/* Select Topic */}
                      {selectedSubject && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel sx={{ color: "#fff" }}>
                            Select Topic
                          </InputLabel>
                          <Select
                            value={selectedTopic}
                            onChange={(e) => setSelectedTopic(e.target.value)}
                            sx={{ border: "1px solid #fff" }}
                          >
                            {topics[selectedSubject].map((topic, idx) => (
                              <MenuItem key={idx} value={topic}>
                                {topic}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {/* Display SubTopics */}
                      {selectedTopic && (
                        <FormControl>
                          <InputLabel sx={{ color: "#fff" }}>
                            Select SubTopic
                          </InputLabel>
                          <Select
                            fullWidth
                            value={selectedSubTopic}
                            onChange={(e) =>
                              setSelectedSubTopic(e.target.value)
                            }
                            sx={{ border: "1px solid #fff" }}
                          >
                            {subTopics[selectedTopic].map((subTopic, idx) => (
                              <MenuItem key={idx} value={subTopic}>
                                {subTopic}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "100px",
                        }}
                      >
                        <button
                          variant="contained"
                          onClick={closeLinkIndexPopup}
                          style={closeButtonStyles}
                        >
                          Cancel
                        </button>

                        <button
                          variant="contained"
                          style={submitButtonStyles}
                          // onClick={handleDrawerClose}
                        >
                          Link Index
                        </button>
                      </div>
                    </Drawer>
                  </form>
                </div>
              </div>
            )}

{(selectedOption === "file" || openLinkIndexPopup === true) && (
  <Popup
    className="popupclass"
    open
    modal
    onClose={() => resetToDefault()}  // Call reset function when modal is closed
  >
    {(close) => (
      <div
        id="modalStorage"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            color: "#fff",
            width: "400px",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            position: "relative",
            fontFamily: "Arial, sans-serif",
            border:"1px solid #fff"
          }}
        >
          <button
            onClick={() => {
              close();
              resetToDefault(); // Reset form on close
            }}
            style={{
              position: "absolute",
              top: "10px",
              right: "15px",
              background: "none",
              border: "none",
              fontSize: "24px",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",
              backgroundColor:"#000",
              color:"#fff"
            }}
          >
            Upload File
          </div>

          <div style={{ marginBottom: "20px" }}>
            <form onSubmit={submitFile}>
            <input
                id="titleinput"
                type="text"
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "15px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                placeholder="Title"
                required
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                onChange={(e) => setCategory(e.target.value)}
                id="categoryinput"
                type="text"
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "15px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                placeholder="Category"
                required
              />
              
              <br />
              <input
                id="fileinputEle"
                type="file"
                style={{
                  width: "100%",
                  padding: "12px",
                  marginBottom: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                accept="*/*"
                required
                onChange={(e) => setFile(e.target.files[0])}
              />
              <br />

              <div style={formFieldStyles}>
                <button
                  style={{backgroundColor:"gray",color:"#fff",border:"none",padding:"10px 15px 10px 15px",width:"100px",borderRadius:"5px"}}
                  type="button"
                  onClick={() => {
                    setIsLinkIndexPopup(true);
                  }}
                >
                  Link Index
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    close();
                    resetToDefault(); // Reset form on cancel
                  }}
                  style={{
                    backgroundColor: "#ff4c4c",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                    flex: "0 0 45%",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                    flex: "0 0 45%",
                  }}
                >
                  {uploadingFile ? (
                    <ClipLoader size={16} color="#ffffff" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )}
  </Popup>
)}



          </div>
        </div>

        {/* <div className="searchrow">
          <div className="searchCon">
            <FontAwesomeIcon className="searchicon" icon={faMagnifyingGlass} />
            <input
              className="searchEle"
              type="search"
              id="searchElement"
              onKeyPress={(e) => handleKeyPress(e)}
              placeholder="Search files..."
              value={searchTerm}
              onChange={onchangeSearch}
            />
          </div>
        </div> */}

        <div className="table-container" style={{ marginTop: "20px" }}>
          <table className="user-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>Category</th>
                <th>Name</th>
                <th>Uploaded Date</th>
                <th>Filesize</th>
                <th>View</th>
                <th>Build</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {allFiles == null || allFiles.length === 0 ? (
                <tr className="trnofiles">
                  <td colSpan="8" className="empty-message">
                    You don't have any files yet :(
                  </td>
                </tr>
              ) : (
                allFiles.map((data, index) => {
                  const serialNo = index + 1; // Generate a serial number based on the index

                  return (
                    <tr key={data._id}>
                      <td>{serialNo}</td> {/* Display the serial number here */}
                      <td>{data.filetype}</td>
                      <td>{data.Category}</td>
                      <td>{data.title}</td>
                      <td>{data.uploadDate}</td>
                      <td>{data.filesize} bytes</td>
                      <td>
                        <button
                          className="showAndDeleteBtn"
                          onClick={() => openFileViewer(data)}
                        >
                          Show File
                        </button>
                      </td>
                      <td>
                        {data.status === "True" ? (
                          <button
                            className="showAndDeleteBtn EmbededButton"
                            onClick={() => EmbededFunctioncall(data)}
                          >
                            Embedded
                          </button>
                        ) : (
                          <button
                            className="showAndDeleteBtn"
                            onClick={() => EmbedCalled(data)}
                          >
                            {embeddingLoading[data._id] ? (
                              <ClipLoader size={16} color="#ffffff" />
                            ) : (
                              "Embed"
                            )}
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="showAndDeleteBtn"
                          onClick={() => confirmDelete(data._id)}
                        >
                          {deletingFile[data._id] ? (
                            <ClipLoader size={16} color="#ffffff" />
                          ) : (
                            "Delete"
                          )}
                        </button>
                        {confirmPop && (
                          <div className="popup-overlay2">
                            <div className="popup-content2">
                              <h2
                                style={{
                                  color: "black",
                                  textAlign: "left",
                                  border: "none",
                                }}
                              >
                                Are you sure you want to delete <br /> this
                                file?
                              </h2>
                              <div className="popup-buttons2">
                                <button
                                  className="cancelBtn2"
                                  onClick={() => handleDeleteCancel()}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="confirmBtn2"
                                  onClick={() => handleConfirmDelete()}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        {/* File Viewer Popup */}
        {selectedFile && (
          <Popup open={true} closeOnDocumentClick onClose={closeFileViewer}>
            {(close) => (
              <div className="modal modal-viewFileCont">
                <button className="close" onClick={close}>
                  &times;
                </button>

                <div className="file-viewer">
                  <h2>{selectedFile.title}</h2>
                  {renderFilePreview(selectedFile)}
                </div>
              </div>
            )}
          </Popup>
        )}

        {/* Loading indicators */}
        {loadingFiles && (
          <div className="loading-overlay">
            <div className="spinner">
              <ClipLoader color="#6c63ff" size={50} />
            </div>
            {/* Optional: Loading message */}
            {/* <p>Loading...</p> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default StorageMainContent;
