import { useEffect, useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";

import { ClipLoader } from "react-spinners";

import "./TestsMainComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faTimes,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

import SidebarDataset from "../SidebarDataset/sidebardataset";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
/*
// Set the workerSrc for PDF.js
import { pdfjs as PdfJsLib } from 'react-pdf';
import 'pdfjs-dist/webpack';

PdfJsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PdfJsLib.version}/pdf.worker.min.js`;


*/

//icon sidebar

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";

function StorageMainContent({ isDarkMode }) {
  const indexes = [
    {
      name: "Index 1",
      exams: ["Exam 1.1", "Exam 1.2"],
    },
    {
      name: "Index 2",
      exams: ["Exam 2.1", "Exam 2.2"],
    },
  ];

  const subjects = {
    "Exam 1.1": ["Subject 1.1.1", "Subject 1.1.2"],
    "Exam 1.2": ["Subject 1.2.1", "Subject 1.2.2"],
    "Exam 2.1": ["Subject 2.1.1", "Subject 2.1.2"],
    "Exam 2.2": ["Subject 2.2.1", "Subject 2.2.2"],
  };

  const topics = {
    "Subject 1.1.1": ["Topic 1.1.1.1", "Topic 1.1.1.2"],
    "Subject 1.1.2": ["Topic 1.1.2.1", "Topic 1.1.2.2"],
    // Add other subjects and their respective topics...
  };

  const subTopics = {
    "Topic 1.1.1.1": ["SubTopic 1.1.1.1.1", "SubTopic 1.1.1.1.2"],
    "Topic 1.1.1.2": ["SubTopic 1.1.1.2.1", "SubTopic 1.1.1.2.2"],
    // Add other topics and their respective subtopics...
  };

  // Styles
  const popupStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    popup: {
      backgroundColor: "black",
      padding: "30px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "400px",
      textAlign: "left",
      border: "1px solid #fff",
    },
  };

  const formStyles = {
    display: "flex",
    flexDirection: "column",
  };

  const formFieldStyles = {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  };

  const inputStyles = {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "97%",
    marginRight: "20px",
  };

  const submitButtonStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    // marginTop: "25px",
  };

  const closeButtonStyles = {
    marginLeft: "10px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100px",
    // marginTop: "25px",
  };

  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [allFiles, setAllFiles] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [uploadmessage, setUploadMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [category, setCategory] = useState("");
  //loading states
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [deletingFile, setDeletingFile] = useState({});
  /*const [embedingLoading,setEmbedingLoading] = useState(false)*/
  const [embeddingLoading, setEmbeddingLoading] = useState({});
  const [confirmPop, setConfirmPopup] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = async () => {
    try {
      setLoadingFiles(true);

      const response = await axios.get(
        `https://legai.onrender.com/get-files?term=${searchTerm}`
      );

      const result = response.data.data;
      console.log(result);

      // If selectType is empty, show all files; otherwise, filter by selectType
      const filteredFiles = selectType
        ? result.filter((item) => item.filetype === `application/${selectType}`)
        : result;

      // Set the state to the filtered or all files
      setAllFiles(filteredFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoadingFiles(false);
      alert("failed to fetch");
    } finally {
      setLoadingFiles(false);
    }
  };

  const submitFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);

    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()}`;
    const formattedTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

    formData.append("uploadDate", formattedDate);
    formData.append("time", formattedTime);
    formData.append("category", category);
    setUploadingFile(true);

    try {
      const result = await axios.post(
        "https://legai.onrender.com/upload-files",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (result.data.status === "ok") {
        alert("Uploaded Successfully!!!");
        // close();
        setTitle("");
        setFile("");
        getFiles();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadingFile(false);
      alert("failed to upload");
    } finally {
      setUploadingFile(false);
    }

    document.getElementById("titleinput").value = "";
    document.getElementById("fileinputEle").value = "";
  };

  const showFile = (file) => {
    setFileUrl(`https://legai.onrender.com/files/${file}`);
  };

  const onchangeSearch = (e) => {
    setSearchTerm(e.target.value);

    if (document.getElementById("searchElement").value === "") {
      getFiles();
      setSearchTerm("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getFiles();
    }
  };

  const searchFile = async () => {
    try {
      setLoadingFiles(true);
      const result = await axios.get(
        `https://legai.onrender.com/api/search-files?term=${searchTerm}`
      );
      if (result.data.status === "ok") {
        setAllFiles(result.data.data);
      }
    } catch (error) {
      console.error("Error searching files:", error);
    } finally {
      setLoadingFiles(false);
    }
  };

  const deleteFile = async (id) => {
    try {
      const updatedEmbeddingLoading = { ...deletingFile, [id]: true };
      setDeletingFile(updatedEmbeddingLoading);
      const result = await axios.delete(
        `https://legai.onrender.com/delete-file/${id}`
      );
      console.log(result);
      console.log(id);
      if (result.data.status === "ok") {
        alert("Deleted Successfully!!!");
        getFiles();
      }
    } catch (error) {
      console.error("Failed to delete file:", error);
    } finally {
      setEmbeddingLoading({ ...deletingFile, [id]: false });
    }
  };

  const openFileViewer = (file) => {
    setSelectedFile(file);
    setNumPages(null);
  };

  // Function to close file viewer popup
  const closeFileViewer = () => {
    setSelectedFile(null);
    setNumPages(null);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const EmbedCalled = async (data) => {
    console.log("embed clicked");
    const updatedEmbeddingLoading = { ...embeddingLoading, [data._id]: true };
    setEmbeddingLoading(updatedEmbeddingLoading);
    try {
      const formData = new FormData();
      formData.append("file", data.pdf);

      console.log("FormData content:", formData.get("file")); // Log the FormData content

      const response = await axios.post(
        "https://legai.onrender.com/embed-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert(response.data);

      console.log(response.data);
      getFiles();
    } catch (error) {
      console.error("Error embedding file:", error);
    } finally {
      setEmbeddingLoading({ ...embeddingLoading, [data._id]: false });
    }
  };

  const EmbededFunctioncall = (data) => {
    if (data.status === "True") {
      alert("Embeded already");
    } else {
      getFiles();
      setUploadMessage("embeded");
      alert("Embedding is Successful!!");
    }
  };

  const renderFilePreview = (file) => {
    if (file.filetype.startsWith("image/")) {
      return (
        <img
          src={`https://legai.onrender.com/files/${file._id}`}
          alt="File Preview"
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype.startsWith("video/")) {
      return (
        <video
          controls
          src={`https://legai.onrender.com/files/${file._id}`}
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype.startsWith("audio/")) {
      return (
        <audio
          controls
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%" }}
        />
      );
    } else if (file.filetype === "application/pdf") {
      return (
        <iframe
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%", height: "50vh" }}
        />
      );
    } else if (file.filetype.startsWith("text/")) {
      return (
        <iframe
          src={`https://legai.onrender.com/${file._id}`}
          style={{ width: "100%", height: "600px" }}
        />
      );
    } else {
      return <p>File type not supported for preview.</p>;
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [drawerOpen2, setDrawerOpen2] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectType, setSelectedType] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isLinkIndexPopup, setIsLinkIndexPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSelectType = (e) => {
    setSelectedType(e.target.value);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const confirmDelete = (fileId) => {
    setConfirmPopup(true);
    setDeleteFileId(fileId);
  };

  const handleConfirmDelete = () => {
    deleteFile(deleteFileId);
    setConfirmPopup(false);
  };

  const handleDeleteCancel = () => {
    setConfirmPopup(false);
    setDeleteFileId(null);
  };

  const togglePopup = () => {
    setIsFormOpen(!isFormOpen);
  };

  const openLinkIndexPopup = () => {
    setIsLinkIndexPopup(true);
  };

  const closeLinkIndexPopup = () => {
    setIsLinkIndexPopup(false);
    setSelectedIndex("");
    setSelectedExam("");
    setSelectedSubject("");
    setSelectedTopic("");
    setSelectedSubTopic("");
  };

  const [formData, setFormData] = useState({
    indexName: "",
    exam: "",
    description: "",
    file: null,
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    console.log("Form Data:", formData);
  };

  const handleDrawerClose = () => {};

  return (
    <div className="mainDatasetContainer">
      <Drawer
        PaperProps={{
          style: {
            backgroundColor: "#252525",
            color: "#ffffff",
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div className="drawerHeader">
          <IconButton
            onClick={toggleDrawer(false)}
            className="drawerCloseButton"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        <List className="deager">
          <SidebarDataset />
        </List>
      </Drawer>

      <div className="sidebarmobile">
        <SidebarDataset />
      </div>

      <div
        className={`  storage-main-content storageDatasetmobile ${
          isDarkMode ? "dark-mode" : "light-mode"
        }`}
      >
        <div className="header">
          <div className="arrowcont">
            {/* <IconButton
                className="sidebaropenbutton"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <FontAwesomeIcon className="drawericon" icon={faGreaterThan} />
              </IconButton> */}
            <h3
              className="storagenamehead"
              style={{ marginBottom: "25px", borderBottom: "2px solid #fff" }}
            >
              Tests
            </h3>
          </div>

          {/* <hr style={{color:"#fff",width:"100%",marginBottom:"20px"}}/> */}
          <div className="uploadButtonCont">
            {/* <div className="arrowcont">
              <IconButton
                className="sidebaropenbutton"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <FontAwesomeIcon className="drawericon" icon={faGreaterThan} />
              </IconButton>
              <h3 className="storagenamehead">Knowledge Base</h3>
            </div> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                gap: "30px",
              }}
            >
              <input
                type="text"
                placeholder="Search by Source"
                style={{
                  width: "180px",
                  padding: "10px",
                  fontSize: "14px",
                  border: "1px solid #333", // Dark border
                  borderRadius: "5px",
                  backgroundColor: "#000", // Black background
                  color: "#fff", // White text
                  marginRight: "10px", // Space between input and dropdown
                }}
              />

              <select
                id="fileTypeSelect"
                onChange={handleSelectType}
                style={{
                  width: "200px",
                  padding: "10px",
                  fontSize: "14px",
                  border: "1px solid #333", // Dark border
                  borderRadius: "5px",
                  backgroundColor: "#000", // Black background
                  color: "#fff", // White text
                  cursor: "pointer",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  outline: "none",
                  appearance: "none", // Hides the default arrow in some browsers
                }}
              >
                <option
                  value=""
                  style={{ color: "#999", backgroundColor: "#000" }}
                >
                  Select a file type
                </option>
                <option
                  value="file"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  File 
                </option>
                <option
                  value="file"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Web Page 
                </option>
                <option
                  value="file"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Youtube Link 
                </option>
                <option
                  value="file"
                  style={{ color: "#fff", backgroundColor: "#000" }}
                >
                  Text
                </option>
                
              </select>
            </div>

            {/* <Popup className="popupclass" trigger={<button id="uploadbtn" className="storagebtn">Upload</button>} modal>
              {close => (
                <div id="modalStorage" className="modalstorage modal2">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <div className="header">Upload File</div>
                  <div className="contentstorage">
                    <form className="formStyle" onSubmit={submitFile}>
                      <input onChange={(e) => setCategory(e.target.value)} id="categoryinput" type="text" className="fileinput form-control" placeholder="Category" required />
                      <input
                        id="titleinput"
                        type="text"
                        className="form-control fileinput"
                        placeholder="Title"
                        required
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <br />
                      <input
                        id="fileinputEle"
                        type="file"
                        className="form-control fileinput"
                        required
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <br />
                      <button className="btnCLassSubmitupload" type="submit">
                        {uploadingFile ? <ClipLoader size={16} color="#ffffff" /> : 'Submit'}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </Popup> */}

            <label
              style={{
                display: "inline-block",
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginBottom: "30px",
              }}
              onClick={togglePopup}
            >
              Add Test
            </label>

            {isFormOpen && (
              <div style={popupStyles.overlay}>
                <div style={popupStyles.popup}>
                  <h2 style={{ margin: 0, textAlign: "center" }}>
                    Upload Test
                  </h2>
                  <form onSubmit={handleSubmit} style={formStyles}>
                    <div style={formFieldStyles}>
                      <label>Test Name</label>
                      <input
                        type="text"
                        name="testName"
                        value={formData.testName}
                        onChange={handleInputChange}
                        style={inputStyles}
                        required
                      />
                    </div>

                    <div style={formFieldStyles}>
                      <label>Test Type</label>
                      {/* <input
                                    type="text"
                                    name="indexName"
                                    value={formData.indexName}
                                    onChange={handleInputChange}
                                    style={inputStyles}
                                    required
                                /> */}
                      <select
                        labelId="test-select-label"
                        id="test-select"
                        value={formData.testType}
                        label="Test Type"
                        onChange={handleInputChange}
                        style={inputStyles}
                        required
                      >
                        <option value={1}>Objective Test</option>
                        <option value={2}>Subjective Test</option>
                      </select>
                    </div>

                    <div style={formFieldStyles}>
                      <label>Upload Test</label>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        style={inputStyles}
                        width="102%"
                        required
                      />
                    </div>

                    <div style={formFieldStyles}>
                      <button
                        style={submitButtonStyles}
                        type="button"
                        onClick={openLinkIndexPopup}
                      >
                        Link Index
                      </button>
                      <p style={{ fontSize: "10px", marginTop: "0px" }}>
                        Linked Index Value
                      </p>

                      {isLinkIndexPopup && (
                        <div>
                          <div>
                            {/* <h2 style={{ margin: 0, textAlign: "center" }}>
                              Link Index
                            </h2> */}
                            <form onSubmit={handleSubmit} style={formStyles}>
                              <Drawer
                                anchor="right"
                                open={isLinkIndexPopup}
                                onClose={handleDrawerClose}
                                PaperProps={{
                                  sx: { width: "350px", padding: "20px" ,background:"#000",borderLeft:"1px solid #fff"},
                                }}
                              >
                                {/* Select Index */}
                                <FormControl fullWidth margin="normal">
                                  <InputLabel sx={{ color: "#fff" }}>
                                    Select Index
                                  </InputLabel>
                                  <Select
                                    value={selectedIndex}
                                    onChange={(e) => {
                                      setSelectedIndex(e.target.value);
                                      setSelectedExam("");
                                      setSelectedSubject("");
                                      setSelectedTopic("");
                                    }}

                                    sx={{border:"1px solid #fff"}}
                                  >
                                    {indexes.map((index, idx) => (
                                      <MenuItem key={idx} value={index}>
                                        {index.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                {/* Select Exam */}
                                {selectedIndex && (
                                  <FormControl fullWidth margin="normal">
                                    <InputLabel sx={{color:"#fff"}}>Select Exam</InputLabel>
                                    <Select
                                      value={selectedExam}
                                      onChange={(e) => {
                                        setSelectedExam(e.target.value);
                                        setSelectedSubject("");
                                        setSelectedTopic("");
                                      }}
                                      sx={{border:"1px solid #fff"}}
                                    >
                                      {selectedIndex.exams.map((exam, idx) => (
                                        <MenuItem key={idx} value={exam}>
                                          {exam}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}

                                {/* Select Subject */}
                                {selectedExam && (
                                  <FormControl fullWidth margin="normal">
                                    <InputLabel sx={{ color: "#fff" }}>
                                      Select Subject
                                    </InputLabel>
                                    <Select
                                      value={selectedSubject}
                                      onChange={(e) => {
                                        setSelectedSubject(e.target.value);
                                        setSelectedTopic("");
                                      }}
                                      sx={{border:"1px solid #fff"}}
                                    >
                                      {subjects[selectedExam].map(
                                        (subject, idx) => (
                                          <MenuItem key={idx} value={subject}>
                                            {subject}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                )}

                                {/* Select Topic */}
                                {selectedSubject && (
                                  <FormControl fullWidth margin="normal">
                                    <InputLabel sx={{ color: "#fff" }}>
                                      Select Topic
                                    </InputLabel>
                                    <Select
                                      value={selectedTopic}
                                      onChange={(e) =>
                                        setSelectedTopic(e.target.value)
                                      }

                                      sx={{border:"1px solid #fff"}}
                                    >
                                      {topics[selectedSubject].map(
                                        (topic, idx) => (
                                          <MenuItem key={idx} value={topic}>
                                            {topic}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                )}

                                {/* Display SubTopics */}
                                {selectedTopic && (
                                  <FormControl>
                                    <InputLabel sx={{ color: "#fff" }}>
                                      Select SubTopic
                                    </InputLabel>
                                    <Select
                                      fullWidth
                                      value={selectedSubTopic}
                                      onChange={(e) =>
                                        setSelectedSubTopic(e.target.value)
                                      }
                                      sx={{border:"1px solid #fff"}}
                                    >
                                      {subTopics[selectedTopic].map(
                                        (subTopic, idx) => (
                                          <MenuItem key={idx} value={subTopic}>
                                            {subTopic}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                )}

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "100px",
                                  }}
                                >
                                  <button
                                    variant="contained"
                                    onClick={closeLinkIndexPopup}
                                    style={closeButtonStyles}
                                  >
                                    Cancel
                                  </button>

                                  <button
                                    variant="contained"
                                    style={submitButtonStyles}
                                    // onClick={handleDrawerClose}
                                  >
                                    Link Index
                                  </button>
                                </div>
                              </Drawer>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        type="button"
                        onClick={togglePopup}
                        style={closeButtonStyles}
                      >
                        Cancel
                      </button>
                      <button type="submit" style={submitButtonStyles}>
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="searchrow">
          <div className="searchCon">
            <FontAwesomeIcon className="searchicon" icon={faMagnifyingGlass} />
            <input
              className="searchEle"
              type="search"
              id="searchElement"
              onKeyPress={(e) => handleKeyPress(e)}
              placeholder="Search files..."
              value={searchTerm}
              onChange={onchangeSearch}
            />
          </div>
        </div> */}

        <div className="table-container" style={{ marginTop: "20px" }}>
          <table className="user-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>Category</th>
                <th>Name</th>
                <th>Uploaded Date</th>
                <th>Filesize</th>
                <th>View</th>
                <th>Build</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {allFiles == null || allFiles.length === 0 ? (
                <tr className="trnofiles">
                  <td colSpan="8" className="empty-message">
                    You don't have any files yet :(
                  </td>
                </tr>
              ) : (
                allFiles.map((data, index) => {
                  const serialNo = index + 1; // Generate a serial number based on the index

                  return (
                    <tr key={data._id}>
                      <td>{serialNo}</td> {/* Display the serial number here */}
                      <td>{data.filetype}</td>
                      <td>{data.Category}</td>
                      <td>{data.title}</td>
                      <td>{data.uploadDate}</td>
                      <td>{data.filesize} bytes</td>
                      <td>
                        <button
                          className="showAndDeleteBtn"
                          onClick={() => openFileViewer(data)}
                        >
                          Show File
                        </button>
                      </td>
                      <td>
                        {data.status === "True" ? (
                          <button
                            className="showAndDeleteBtn EmbededButton"
                            onClick={() => EmbededFunctioncall(data)}
                          >
                            Embedded
                          </button>
                        ) : (
                          <button
                            className="showAndDeleteBtn"
                            onClick={() => EmbedCalled(data)}
                          >
                            {embeddingLoading[data._id] ? (
                              <ClipLoader size={16} color="#ffffff" />
                            ) : (
                              "Embed"
                            )}
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="showAndDeleteBtn"
                          onClick={() => confirmDelete(data._id)}
                        >
                          {deletingFile[data._id] ? (
                            <ClipLoader size={16} color="#ffffff" />
                          ) : (
                            "Delete"
                          )}
                        </button>
                        {confirmPop && (
                          <div className="popup-overlay2">
                            <div className="popup-content2">
                              <h2
                                style={{
                                  color: "black",
                                  textAlign: "left",
                                  border: "none",
                                }}
                              >
                                Are you sure you want to delete <br /> this
                                file?
                              </h2>
                              <div className="popup-buttons2">
                                <button
                                  className="cancelBtn2"
                                  onClick={() => handleDeleteCancel()}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="confirmBtn2"
                                  onClick={() => handleConfirmDelete()}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        {/* File Viewer Popup */}
        {selectedFile && (
          <Popup open={true} closeOnDocumentClick onClose={closeFileViewer}>
            {(close) => (
              <div className="modal modal-viewFileCont">
                <button className="close" onClick={close}>
                  &times;
                </button>

                <div className="file-viewer">
                  <h2>{selectedFile.title}</h2>
                  {renderFilePreview(selectedFile)}
                </div>
              </div>
            )}
          </Popup>
        )}

        {/* Loading indicators */}
        {loadingFiles && (
          <div className="loading-overlay">
            <div className="spinner">
              <ClipLoader color="#6c63ff" size={50} />
            </div>
            {/* Optional: Loading message */}
            {/* <p>Loading...</p> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default StorageMainContent;
